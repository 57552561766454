import React from 'react';
import { Link } from 'react-router-dom';
import './css/Footer.css';
import Logo from './../../images/logo-footer.png';
import { connect } from 'react-redux';

function Footer(props) {
  const {
    siteReducer: {
      site_texts: {
        text_menu,
        text_about_us,
        text_faq,
        text_privacy_policy,
        text_terms_conditions,
        text_video,
        text_links,
        text_my_account,
        text_cart,
        text_directory,
        text_form_login,
        text_my_profile,
        text_my_orders,
        text_my_products,
        text_lets_talk,
        text_follow_us_on,
      },
    },
  } = props;
  const date = new Date();

  return (
    <React.Fragment>
      <div className="background-purple footer_content">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <img src={Logo} alt="Logo BAX" className="footer_logo" />
            </div>
            <div className="col-md-2 col-sm-6">
              <span className="footer_title-menu">
                {text_menu ? text_menu : 'Menú'}
              </span>
              <div className="footer_menu">
                <a
                  href="https://baxnet.co/#valores"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text_about_us ? text_about_us : 'Nosotros'}
                </a>
                <a
                  href="https://influenx.co/#faq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text_faq ? text_faq : 'FAQ'}
                </a>
                <a
                  href="https://influenx.co/politica-de-privacidad/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text_privacy_policy
                    ? text_privacy_policy
                    : 'Políticas de Privacidad'}
                </a>
                <a
                  href="https://influenx.co/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text_terms_conditions
                    ? text_terms_conditions
                    : 'Términos y Condiciones'}
                </a>
                <a
                  href="https://youtu.be/wcsgulA02l4?list=TLGGizUTlIHxfQEyNjA4MjAyMQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text_video ? text_video : 'Video'}
                </a>
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <span className="footer_title-menu">
                {text_links ? text_links : 'Enlaces'}
              </span>
              <div className="footer_menu">
                <Link to="/">Marketplace</Link>
                <Link to="/manager/settings">
                  {text_my_account ? text_my_account : 'Mi Cuenta'}
                </Link>
                <Link to="/cart">
                  {text_cart ? text_cart : 'Carrito de Compras'}
                </Link>
                <Link to="/companies">
                  {text_directory ? text_directory : 'Directorio'}
                </Link>
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <span className="footer_title-menu">
                {text_my_account ? text_my_account : 'Mi Cuenta'}
              </span>
              <div className="footer_menu">
                <Link to="/login">
                  {text_form_login ? text_form_login : 'Iniciar Sesión'}
                </Link>
                <Link to="/manager/profile">
                  {text_my_profile ? text_my_profile : 'Mi Perfil'}
                </Link>
                <Link to="/manager/orders">
                  {text_my_orders ? text_my_orders : 'Mis Órdenes'}
                </Link>
                <Link to="/manager/products">
                  {text_my_products ? text_my_products : 'Mis Productos'}
                </Link>
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <span className="footer_title-menu">
                {text_lets_talk ? text_lets_talk : 'Hablemos'}
              </span>
              <ul className="list-unstyled footer_contact">
                <li>Calle 127a no 45-96 of.501</li>
                <li>Tel.: 3172763543</li>
                <li>Email: info@baxnet.co</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer_sharer">
          <div className="footer_title-menu">
            {text_follow_us_on ? text_follow_us_on : 'Siguenos en'}
          </div>
          <div className="footer_sharer-social">
            <a
              href="https://www.facebook.com/influenx"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook-f"></i>
            </a>
            <a
              href="https://www.instagram.com/bax_net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>
            <a
              href="https://twitter.com/baxnet_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-twitter"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/baxnet/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="footer_copy">
        Made with ❤ by{' '}
        <a
          href="https://believe-global.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Believe
        </a>
        ! © Copyright Believe It Group 2013-
        {date.getFullYear()}. All Rights Reserved.​
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(Footer);
